import React, { useState, useEffect } from "react";
import api from "../components/AxiosDefault";
import "../style/stakeholderProject.scss";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

import {
  Button,
  Table,
  Tag,
  Space,
  Modal,
  Input,
  Form,
  DatePicker,
  notification,
} from "antd";

const StakeholderProject = () => {
  const decoded = jwtDecode(localStorage.getItem("access_token"));
  const [apinot, contextHolder] = notification.useNotification();
  const [showModal, setShowModal] = useState(false); // 使否顯示新增項目Modal
  const [form] = Form.useForm();
  const navigate = useNavigate();

  // const [projectData, setProjectData] = useState(null);
  const [projectData, setProjectData] = useState([
    {
      id: 0,
      projectName: "",
      projectYear: 0,
      state: "",
    },
  ]);
  // 補後端一開始進來先抓資料存在projectData
  const columns = [
    {
      title: "項目名稱",
      dataIndex: "projectName",
      key: "projectName",
    },
    {
      title: "年分",
      dataIndex: "projectYear",
      key: "projectYear",
    },
    {
      title: "進度",
      key: "state",
      dataIndex: "state",
      render: (_, { state }) => (
        //   <>
        //     {/* <Modal
        //       title="是否確認刪除"
        //       open={showDModal}
        //       onOk={handleDel}
        //       onCancel={onCancel}
        //     /> */}
        <Tag
          color={
            state === "未開始"
              ? "default"
              : state === "已完成"
              ? "success"
              : "processing"
          }
          key={state}
        >
          {state ? state : "未開始"}
        </Tag>
        //   </>
      ),
    },
    {
      title: "操作",
      dataIndex: "edit",
      key: "edit",
      render: (_, record) => (
        <Space size="middle">
          <a
            onClick={() => {
              navigate("/Stakeholder", {
                state: { record },
              });
            }}
          >
            進入項目
          </a>
          <a
            onClick={() => {
              // 補後端刪除project
              handleDeleteProject(record.id);
            }}
          >
            刪除
          </a>
        </Space>
        // <div>測試</div>
      ),
    },
  ];
  const getProjects = () => {
    api
      .get("/projects/" + decoded["corporateId"])
      .then((response) => {
        console.log(response.data);
        setProjectData(response.data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  useEffect(() => {
    getProjects();
  }, []);
  // 新增利害關係人議合項目
  const addProject = () => {
    setShowModal(true);
    console.log("s");
  };

  const handleCancel = () => {
    form.resetFields();
    setShowModal(false);
    console.log("handleCancel");
  };
  const handleDeleteProject = (projectId) => {
    api
      .delete("/projects/" + projectId)
      .then((response) => {
        if (response.status === 204) {
          getProjects();
          apinot["success"]({
            message: "通知",
            description: "刪除成功",
          });
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  };
  const handleAddProject = () => {
    form.validateFields().then((values) => {
      values.projectYear = parseInt(values.projectYear.format("YYYY"), 10);
      // console.log(values["projectName"]);
      let projectsData = new FormData();
      projectsData.append("projectYear", values["projectYear"]);
      projectsData.append("projectName", values["projectName"]);
      projectsData.append("corporateId", decoded["corporateId"]);

      // console.log(projectsData);
      api
        .post("/projects", projectsData)
        .then((result) => {
          console.log(result.status);
          if (result.status === 201) {
            apinot["success"]({
              message: "通知",
              description: "新增成功",
            });
            getProjects();
          }
        })
        .catch((error) => console.error("Error fetching data:", error));
      form.resetFields();
    });
    setShowModal(false);
  };
  return (
    <>
      {contextHolder}
      <Modal
        // forceRender
        title="新增利害關係人議合項目"
        open={showModal}
        okText="儲存"
        cancelText="取消"
        onOk={handleAddProject}
        onCancel={handleCancel}
        destroyOnClose={true}
      >
        <Form
          name="project-form"
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ spane: 20 }}
        >
          <Form.Item
            label="項目名稱"
            name="projectName"
            hasFeedback
            rules={[{ required: true, message: "請填寫項目名稱" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="年分"
            name="projectYear"
            hasFeedback
            rules={[{ required: true, message: "請輸入年分" }]}
          >
            <DatePicker picker="year" />
          </Form.Item>
        </Form>
      </Modal>
      <div className="stakeholderProject">
        <Button className="button" onClick={addProject}>
          新增利害關係人議合項目
        </Button>
        {projectData == null ? (
          <div>暫無項目，請點選上方按鈕新增項目</div>
        ) : (
          <Table
            dataSource={projectData}
            columns={columns}
            rowKey="id"
            className="table"
          />
        )}
      </div>
    </>
  );
};

export default StakeholderProject;
