import React, { useEffect, useState } from "react";
import { Table, Tag, Space, Modal, notification, Button, Select } from "antd";
import "../style/reportList.scss";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import api from "../components/AxiosDefault";
import { jwtDecode } from "jwt-decode";

const ReportList = (props) => {
  const [option, setOption] = useState([]);
  const decoded = jwtDecode(localStorage.getItem("access_token"));
  const [apinot, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const [showDModal, setShowDModal] = useState(false); //是否顯示刪除modal對話框
  const [delID, setDelID] = useState(); //刪除ID
  const [WTList, setWTList] = useState();
  const columns = [
    {
      title: "重大議題",
      dataIndex: "materialTopics",
      key: "materialTopics",
    },
    {
      title: "說明",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "進度",
      key: "state",
      dataIndex: "state",
      render: (_, { state }) => (
        <>
          <Modal
            title="是否確認刪除"
            open={showDModal}
            onOk={handleDel}
            onCancel={onCancel}
          />
          <Tag
            color={
              state === "進行中"
                ? "processing"
                : state === "已完成"
                ? "success"
                : "default"
            }
            key={state}
          >
            {state ? state : "未開始"}
          </Tag>
        </>
      ),
    },
    {
      title: "更新時間",
      dataIndex: "updateTime",
      key: "updateTime",
      render: (_, { updateTime }) => (
        <>
          {updateTime ? moment(updateTime).format("YYYY-MM-DD HH:mm:ss") : null}
        </>
      ),
    },
    {
      title: "操作",
      dataIndex: "edit",
      key: "edit",
      render: (_, record) => (
        <Space size="middle">
          <a
            onClick={() => {
              navigate("/Report", {
                state: { record },
              });
            }}
          >
            編輯
          </a>
          <a
            onClick={() => {
              handleOpenDelModal(record.id);
            }}
          >
            刪除
          </a>
        </Space>
      ),
    },
  ];
  useEffect(() => {
    let corporateData = new FormData();
    corporateData.append("corporateId", decoded["corporateId"]);
    api
      .post("/getcorprojects", corporateData)
      .then((result) => {
        const formattedProjects = result.data.map((project) => ({
          value: project.id.toString(), // 将 id 转换为字符串
          label: project.projectName, // 使用 projectName 作为 label
        }));
        setOption(formattedProjects);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);
  // 改變選單
  const handleChange = (value) => {
    getsetWTList(value);
  };
  // 取得資料api
  const getsetWTList = (value) => {
    let corporateData = new FormData();
    // corporateData.append("corporateId", decoded["corporateId"]);
    corporateData.append("projectid", value);

    api
      .post("/writeTopic", corporateData)
      .then((result) => {
        setWTList(result.data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  };
  // 刪除資料api
  const delWTList = () => {
    api
      .delete("/writeTopic/" + delID)
      .then((response) => {
        if (response.status === 204) {
          getsetWTList();
          apinot["success"]({
            message: "通知",
            description: "刪除成功",
          });
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  };
  // 點擊刪除
  const handleOpenDelModal = (id) => {
    setDelID(id);
    setShowDModal(true);
  };
  // 確認刪除
  const handleDel = async (e) => {
    delWTList();
    setShowDModal(false);
    setDelID(null);
  };
  // 取消刪除
  const onCancel = (e) => {
    setDelID(null);
    setShowDModal(false);
  };

  return (
    <div className="reportList">
      {contextHolder}

      <Select
        defaultValue="請選擇項目"
        style={{
          width: 200,
          margin: "2%",
        }}
        onChange={handleChange}
        options={option}
      />
      {WTList && (
        <>
          <div className="title">重大議題清單</div>
          <div className="table">
            <Table dataSource={WTList} columns={columns} rowKey="id" />
          </div>
          <Button type="primary">下載PDF</Button>
        </>
      )}
    </div>
  );
};
export default ReportList;
