import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../style/stakeholder.scss";
import { Button, message, Steps, theme } from "antd";
import Questionnaire from "./view2/Questionnaire";
import Majorpic from "./view2/Majorpic";
import MajorList from "./view2/MajorList";
import Identification from "./view2/identification";
import api from "../components/AxiosDefault";

const Stakeholder = () => {
  // const [isIdentificationEditable, setIsIdentificationEditable] =
  // useState(true); //是否完成step1

  let location = useLocation();
  const record = location.state.record;
  // const [isEditable, setIsEditable] = useState(true);
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0); //目前的進度
  const [projectState, setProjectState] = useState(record.state); // 項目狀態
  const [nextDisabled, setNextDisabled] = useState(false); // Next按钮是否被禁用
  // 根据项目状态和当前步骤来决定是否禁用“Next”按钮
  // const shouldDisableNextButton = () => {
  //   switch (projectState) {
  //     case "未開始":
  //       return current === 0; // 如果项目未开始，且当前在第一步，则禁用“Next”按钮
  //     case "完成利害關係人鑑別":
  //       return current >= 1; // 如果完成了第一步，且当前在第二步或之后，则禁用“Next”按钮
  //     case "完成蒐集重大議題":
  //       return current >= 2; // 依此类推...
  //     // 根据您的需求添加更多的状态处理逻辑
  //     default:
  //       return false; // 默认不禁用“Next”按钮
  //   }
  // };
  useEffect(() => {
    // 根据项目状态和当前步骤决定是否禁用“Next”按钮
    const disableNextButton =
      (projectState === "未開始" && current === 0) ||
      (projectState === "完成利害關係人鑑別" && current >= 1) ||
      (projectState === "完成蒐集重大議題" && current >= 2) ||
      (projectState === "完成問卷" && current >= 3);

    // console.log("=============================");
    // console.log(projectState);
    // console.log(current);
    // console.log("=============================");
    // console.log(projectState);
    // console.log(current);
    // console.log("=============================");
    // console.log(projectState);
    // console.log(current);
    // console.log("=============================");
    // console.log(projectState);
    // console.log(current);
    // console.log("=============================");

    setNextDisabled(disableNextButton);
  }, [projectState, current]); // 依赖于projectState和current
  const steps = [
    {
      title: "利害關係人鑑別",
      content: (
        <Identification
          project={record}
          setIsEditable={setProjectState}
          isEditable={projectState == "未開始"}
        />
      ),
    },
    {
      title: "蒐集重大議題",
      content: (
        <MajorList
          project={record}
          setIsEditable={setProjectState}
          isEditable={projectState == "完成利害關係人鑑別"}
        />
      ),
    },
    {
      title: "關注程度/衝擊程度問卷調查",
      content: (
        <Questionnaire
          project={record}
          setIsEditable={setProjectState}
          isEditable={projectState == "完成蒐集重大議題"}
        />
      ),
    },
    {
      title: "重大主題分析",
      content: (
        <Majorpic
          project={record}
          setIsEditable={setProjectState}
          isEditable={projectState == "完成問卷"}
        />
      ),
    },
  ];
  const next = () => {
    setCurrent(current + 1);
    // record.s=== "完成利害關係人鑑別" && setIsEditable(false);
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const contentStyle = {
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    padding: "2%",
  };
  const sendDone = () => {
    let saveData = new FormData();
    console.log(record["id"]);
    saveData.append("projectid", record["id"]);
    api.post("/newwriteTopic", saveData).then((result) => {
      if (result.status === 201) {
        let saveData = new FormData();
        saveData.append("projectid", record["id"]);
        saveData.append("state", "已完成");
        api.patch("/projects", saveData).then((result) => {
          if (result.status === 200) {
            console.log(result);
            setProjectState("已完成");
            message.success("可前往製作報告書!");
          }
        });
      }
    });
  };

  return (
    <div className="stakeholder">
      <Steps current={current} items={items} className="steps" />
      <div style={contentStyle}>{steps[current].content}</div>
      <div
        style={{
          marginTop: 24,
        }}
      >
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()} disabled={nextDisabled}>
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            type="primary"
            onClick={sendDone}
            disabled={projectState === "已完成"}
          >
            Done
          </Button>
        )}
        {current > 0 && (
          <Button
            style={{
              margin: "0 8px",
            }}
            onClick={() => prev()}
          >
            Previous
          </Button>
        )}
      </div>
    </div>
  );
};

export default Stakeholder;
