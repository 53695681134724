import axios from "axios";
import { path_server, request_delay,path } from "./Constants";

// 创建 Axios 实例
const api = axios.create({
  baseURL: path_server,
  timeout: request_delay,
  headers: {
    "Content-type": "application/json",
  },
});

// 清除本地存储中的令牌
const clearTokens = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
};

// 请求拦截器，在每个请求中添加访问令牌
api.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器，处理访问令牌过期的情况
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem("refresh_token");
      if (refreshToken) {
        try {
          // 发送刷新令牌请求以获取新的访问令牌
          const response = await api.post("/api/token/refresh/", {
            refresh: refreshToken,
          });
          const { access: accessToken } = response.data;
          localStorage.setItem("access_token", accessToken);
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          return api(originalRequest);
        } catch (error) {
          clearTokens();
          // 在这里不应该使用 navigate 因为我们将逻辑移到了非组件代码
          // 而是应该考虑抛出错误或其他机制来通知用户需要重新登录
          console.log("重新登入");
          window.location = path+"/loginpage"; // 修改为您的登录页面的路径

          return Promise.reject("Login required");
        }
      } else {
        clearTokens();
        window.location = path+"/loginpage"; // 修改为您的登录页面的路径

        return Promise.reject("Login required");
      }
    }
    return Promise.reject(error);
  }
);

export default api;

////////////////////////////////////////////////////////////////////////////////////////////

// import { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import { path_server, request_delay } from "./Constants";

// const useApi = () => {
//   const navigate = useNavigate(); // 移到 Hook 内部使用
//   const api = axios.create({
//     baseURL: path_server,
//     timeout: request_delay,
//     // headers: {
//     //   "Content-type": "application/json",
//     // },
//   });

//   useEffect(() => {
//     const clearTokens = () => {
//       localStorage.removeItem("accessToken");
//       localStorage.removeItem("refreshToken");
//     };

//     // 请求拦截器
//     api.interceptors.request.use(
//       (config) => {
//         console.log("Request interceptor called", config);

//         const accessToken = localStorage.getItem("access_token");

//         if (accessToken) {
//           config.headers.Authorization = `Bearer ${accessToken}`;
//         }
//         return config;
//       },
//       (error) => {
//         return Promise.reject(error);
//       }
//     );

//     // 响应拦截器
//     api.interceptors.response.use(
//       (response) => response,
//       async (error) => {
//         const originalRequest = error.config;
//         if (error.response.status === 401 && !originalRequest._retry) {
//           originalRequest._retry = true;
//           const refreshToken = localStorage.getItem("refresh_token");
//           if (refreshToken) {
//             try {
//               const response = await api.post("/api/token/refresh/", {
//                 refresh: refreshToken,
//               });
//               // const { access: accessToken, refresh: newRefreshToken } =
//               //   response.data;
//               const { access: accessToken } = response.data;
//               console.log(accessToken);
//               localStorage.setItem("access_token", accessToken);
//               // localStorage.setItem("refresh_token", newRefreshToken);
//               originalRequest.headers.Authorization = `Bearer ${accessToken}`;
//               return api(originalRequest);
//             } catch (error) {
//               clearTokens();
//               navigate("/loginpage");
//               return Promise.reject(error);
//             }
//           } else {
//             clearTokens();
//             navigate("/loginpage");
//             return Promise.reject(error);
//           }
//         }
//         return Promise.reject(error);
//       }
//     );
//     return api; // 注意：这个 Hook 返回 axios 实例
//   }, [navigate]); // 添加 navigate 作为依赖项

//   return api;
// };

// export default useApi;
///////////////////////////////////////////////////////////////////////////////////

// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import { path_server, request_delay } from "./Constants";
// const api_token = localStorage.getItem("access_token")
//   ? localStorage.getItem("access_token")
//   : "";
// const navigate = useNavigate();
// // 清除本地存储中的令牌
// const clearTokens = () => {
//   localStorage.removeItem("accessToken");
//   localStorage.removeItem("refreshToken");
// };
// export const api = axios.create({
//   baseURL: path_server,
//   timeout: request_delay,
//   headers: {
//     // Authorization: "Bearer " + window.localStorage.getItem("access_token"),
//     // "Content-Type": "multipart/form-data",
//     "Content-type": "application/json",
//   },
// });
// // 请求拦截器，在每个请求中添加访问令
// api.interceptors.request.use((config) => {
//   const accessToken = window.localStorage.getItem("access_token");
//   if (accessToken) {
//     config.headers.Authorization = `Bearer ${accessToken}`;
//   }
//   return config;
// });

// // 响应拦截器，处理访问令牌过期的情况
// api.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const originalRequest = error.config;
//     if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       const refreshToken = window.localStorage.getItem("refresh_token");
//       if (refreshToken) {
//         try {
//           // 发送刷新令牌请求以获取新的访问令牌
//           const response = await axios.post("/api/token/refresh", {
//             refreshToken,
//           });
//           const { accessToken, refreshToken: newRefreshToken } = response.data;
//           localStorage.setItem("access_token", accessToken);
//           localStorage.setItem("refresh_token", newRefreshToken);
//           originalRequest.headers.Authorization = `Bearer ${accessToken}`;
//           return api(originalRequest);
//         } catch (error) {
//           clearTokens();
//           navigate("/loginpage"); // 重定向到登录页面
//           return Promise.reject(error);
//         }
//       } else {
//         clearTokens();
//         navigate("/loginpage"); // 重定向到登录页面
//         return Promise.reject(error);
//       }
//     }
//     return Promise.reject(error);
//   }
// );

// export const noAuthAPI = axios.create({
//   baseURL: path_server,
//   timeout: request_delay,
// });
