import React, { useEffect, useState } from "react";
import "../style/information.scss";
import { 
  Button, 
  Input, 
  Form,
  Modal, 
  notification, 
  Empty,
} from "antd";
import { PictureFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import api from "../components/AxiosDefault";
import { jwtDecode } from "jwt-decode";

const Information = (props) => {
  const decoded = jwtDecode(localStorage.getItem("access_token"));
  const [apinot, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const [showEditModal, setShowEditModal] = useState(false); //是否顯示modal對話框
  const [form] = Form.useForm();
  const [data, setData] = useState()

  // const [data, setData] = useState({
  //   id: 0,
  //   name: 0,
  //   headquarters: 0,
  //   industry: 0,
  //   employeeCount: 0,
  //   established: 0,
  //   companyProfile: 0,
  //   overview: 0,
  // });
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    api
      .get("/corporateInfo/" + decoded["corporateId"])
      .then((response) => {
        console.log(response.data);
        setData(response.data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  // 編輯
  const handleOpenEditModal = (e) => {
    setShowEditModal(true);
  };
  // 確認編輯
  const handleEditInfo = (e) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      values["name"] = data.name;
      console.log(values);
      form.resetFields();
      api
        .put("/corporateInfo/" + decoded["corporateId"], values) // 使用 Axios 实例发送 PUT 请求
        .then((response) => {
          if (response.status === 200) {
            apinot["success"]({
              // 假设这是你的通知函数
              message: "通知",
              description: "編輯成功",
            });
            getData(); // 假设这是获取数据的函数
          }
        })
        .catch((error) => console.error("Error fetching data:", error));
    });
    setShowEditModal(false);
  };
  // 取消編輯
  const onCancel = (e) => {
    form.resetFields();
    setShowEditModal(false);
  };
  return (
    <>
      {contextHolder}
      <Modal
        // forceRender
        title="修改公司基本資料"
        open={showEditModal}
        okText="存檔"
        cancelText="取消"
        onOk={handleEditInfo}
        onCancel={onCancel}
        destroyOnClose={true}
        width={800}
      >
        <Form
          name="corporateInfo-form"
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ spane: 20 }}
          initialValues={{
            headquarters: data ? data.headquarters : "",
            industry: data ? data.industry : "",
            employeeCount: data ? data.employeeCount : "",
            established: data ? data.established : "",
            companyProfile: data ? data.companyProfile : "",
            overview: data ? data.overview : "",
          }}
        >
          <Form.Item label="公司名稱">{data ? data.name : ""}</Form.Item>
          <Form.Item
            label="總部"
            name="headquarters"
            hasFeedback
            rules={[{ required: true, message: "請填寫總部" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="產業類別"
            name="industry"
            hasFeedback
            rules={[{ required: true, message: "請填寫產業類別" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="員工數"
            name="employeeCount"
            hasFeedback
            rules={[{ required: true, message: "請填寫員工數" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="成立時間"
            name="established"
            hasFeedback
            rules={[{ required: true, message: "請填寫成立時間" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="公司簡介"
            name="companyProfile"
            hasFeedback
            rules={[{ required: true, message: "請填寫公司簡介" }]}
          >
            <Input.TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
          </Form.Item>
          <Form.Item
            label="經營概況"
            name="overview"
            hasFeedback
            rules={[{ required: true, message: "請填寫經營概況" }]}
          >
            <Input.TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
          </Form.Item>
        </Form>
      </Modal>

      <div className="information">
      {data ? (
          <>
        <div className="information_list">
          <div className="title">公司基本資料</div>

          <div className="information_list_item">
            <div className="list_item_item">
              <div className="itme_title">
                公司名稱：<div className="text">{data.name ? data.name : "尚未填寫"}</div>
              </div>
              <div className="itme_title">
                總部：<div className="text">{data.headquarters ? data.headquarters : "尚未填寫"}</div>
              </div>
              <div className="itme_title">
                產業類別：<div className="text">{data.industry ? data.industry : "尚未填寫"}</div>
              </div>
              <div className="itme_title">
                員工數：<div className="text">                      
                  {data.employeeCount
                        ? data.employeeCount + "人"
                        : "尚未填寫"}</div>
              </div>
              <div className="itme_title">
                成立時間：<div className="text"> {data.established ? data.established : "尚未填寫"}</div>
              </div>
            </div>
            <PictureFilled className="icon" />
          </div>
        </div>
        <div className="information_list">
          <div className="title">公司簡介</div>
          <div className="text">　　{data.companyProfile ? data.companyProfile : "尚未填寫"}</div>
        </div>

        <div className="information_list">
          <div className="title">經營概況</div>
          <div className="text">　　{data.overview ? data.overview : "尚未填寫"}
          </div>
        </div>
        <div className="button">
          <Button
            onClick={() => {
              handleOpenEditModal();
            }}
          >
            編輯
          </Button>
        </div>
         </>
        ) : (
          <div>
            <Empty description={false} />
          </div>
        )}
      </div>
    </>
  );
};
export default Information;
