import React, { useState, useRef } from "react";
import { stateToHTML } from "draft-js-export-html";
import "draft-js/dist/Draft.css";
import { Button } from "antd";

import {
  convertToRaw,
  Editor,
  EditorState,
  getDefaultKeyBinding,
  RichUtils,
  ContentState,
  convertFromHTML,
} from "draft-js";
import "./RichEditor.css";

// 定義一個函數用於獲取區塊的樣式
const getBlockStyle = (block) => {
  switch (block.getType()) {
    case "blockquote":
      return "RichEditor-blockquote";
    default:
      return null;
  }
};

// 定義一個對象包含自定義的樣式映射
const styleMap = {
  CODE: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

// 定義一個函數式組件用於渲染區塊樣式控制按鈕
const BlockStyleControls = (props) => {
  const { editorState, onToggle } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  // 定義一個數組包含不同的區塊樣式
  const BLOCK_TYPES = [
    { label: "H1", style: "header-one" },
    { label: "H2", style: "header-two" },
    { label: "H3", style: "header-three" },
    { label: "H4", style: "header-four" },
    // { label: "H5", style: "header-five" },
    // { label: "H6", style: "header-six" },
    { label: "Blockquote", style: "blockquote" },
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
    { label: "Code Block", style: "code-block" },
  ];

  // 渲染按鈕
  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

// 定義一個函數式組件用於渲染行內樣式控制按鈕
const InlineStyleControls = (props) => {
  const { editorState, onToggle } = props;
  const currentStyle = editorState.getCurrentInlineStyle();

  // 定義一個數組包含不同的行內樣式
  const INLINE_STYLES = [
    { label: "Bold", style: "BOLD" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
    { label: "Monospace", style: "CODE" },
  ];

  // 渲染按鈕
  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

// 定義一個函數式組件用於渲染樣式按鈕
const StyleButton = (props) => {
  const { label, onToggle, style, active } = props;

  // 處理按鈕點擊事件
  const onToggleClick = (e) => {
    e.preventDefault();
    onToggle(style);
  };

  // 根據是否激活設置CSS類
  let className = "RichEditor-styleButton";
  if (active) {
    className += " RichEditor-activeButton";
  }

  // 渲染按鈕
  return (
    <span className={className} onMouseDown={onToggleClick}>
      {label}
    </span>
  );
};
const createEditorStateFromHTML = (html) => {
  const blocksFromHTML = convertFromHTML(html);
  const state = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );
  return EditorState.createWithContent(state);
};
// 定義RichTextEditor函數式組件
const RichTextEditor = ({
  initialHtml,
  editSaveContent,
  editCancelContent,
}) => {
  // 使用useState Hook來管理editorState的狀態

  //   const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorState, setEditorState] = useState(
    initialHtml
      ? createEditorStateFromHTML(initialHtml)
      : EditorState.createEmpty()
  );
  //   console.log(initialHtml);

  // 使用useRef Hook來參考editor元素
  const editor = useRef(null);

  // 將焦點設定到Editor上
  const focusEditor = () => {
    if (editor.current) {
      editor.current.focus();
    }
  };

  // 更新Editor的狀態
  const onChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  // 處理鍵盤命令
  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return "handled";
    }
    return "not-handled";
  };

  // 映射鍵盤命令到Editor命令
  const mapKeyToEditorCommand = (event) => {
    if (event.keyCode === 9) {
      // TAB
      const newEditorState = RichUtils.onTab(event, editorState, 4);
      if (newEditorState !== editorState) {
        onChange(newEditorState);
      }
      return;
    }
    return getDefaultKeyBinding(event);
  };

  // 切換區塊樣式
  const toggleBlockType = (blockType) => {
    onChange(RichUtils.toggleBlockType(editorState, blockType));
  };

  // 切換行內樣式
  const toggleInlineStyle = (inlineStyle) => {
    onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  // 根據當前的editorState來決定CSS類別
  let className = "RichEditor-editor";
  const contentState = editorState.getCurrentContent();
  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== "unstyled") {
      className += " RichEditor-hidePlaceholder";
    }
  }
  const logContent = () => {
    // const content = editorState.getCurrentContent();
    // console.log(convertToRaw(content));
    const contentState = editorState.getCurrentContent();
    const Contenthtml = stateToHTML(contentState);
    // console.log(Contenthtml); // Or save it as needed
    editSaveContent(Contenthtml);
  };

  // 渲染組件
  return (
    <>
      <div className="RichEditor-root">
        <BlockStyleControls
          editorState={editorState}
          onToggle={toggleBlockType}
        />
        <InlineStyleControls
          editorState={editorState}
          onToggle={toggleInlineStyle}
        />
        <div className={className} onClick={focusEditor}>
          <Editor
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            editorState={editorState}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={mapKeyToEditorCommand}
            onChange={onChange}
            placeholder="Tell a story..."
            ref={editor}
            spellCheck={true}
          />
        </div>
      </div>
      {/* <button onClick={logContent}>完成</button> */}
      {/* <div
        style={{ marginTop: "20px", border: "1px solid black", padding: "5px" }}
      >
        <Editor editorState={editorState} readOnly={true} />
      </div> */}{" "}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button type="primary" style={{ margin: "1%" }} onClick={logContent}>
          儲存
        </Button>
        <Button
          // type="primary"
          style={{ margin: "1%" }}
          onClick={editCancelContent}
        >
          取消
        </Button>
      </div>
    </>
  );
};

export default RichTextEditor;
