import React, { useState, useEffect } from "react";
import "../../style/questionnaire.scss";
import {
  Button,
  Radio,
  Segmented,
  message,
  Input,
  Tooltip,
  Space,
  Row,
  Col,
  Modal,
  notification,
  DatePicker,
} from "antd";
import { CopyOutlined, SendOutlined, SaveOutlined } from "@ant-design/icons";
import api from "../../components/AxiosDefault";
import moment from "moment";

const { RangePicker } = DatePicker;

const Questionnaire = (props) => {
  const isEditable = props.isEditable;
  console.log(isEditable);
  const [apinot, contextHolder] = notification.useNotification();

  const [isSendModalOpen, setIsSendModalOpen] = useState(false); // 使否顯示新增Modal
  const [editContacts, setEditContacts] = useState("");
  const [editEmail, setEditEmail] = useState("");
  const [editTime, setEditTime] = useState([
    "2024-04-27 06:00:00.000000",
    "2024-04-27 06:00:00.000000",
  ]);

  const [showImpact, setShowImpact] = useState(false);
  const [topicsRadios, setTopicsRadios] = useState([]);
  const [corName, setCorName] = useState(""); //公司名稱
  // const [qinfo, setQinfo] = useState(""); //問卷資料
  const [stackholders, setStackholders] = useState([]);
  const impactDescriptions = [
    "正面潛在衝擊議題的程度",
    "正面實際衝擊議題的程度",
    "負面實際衝擊議題的程度",
    "負面潛在衝擊議題的程度",
  ];

  const [topics, setTopics] = useState([
    {
      id: 0,
      physiognomy: "面相",
      materialTopics: "重大議題",
      description: "定義與說明",
    },
  ]);

  // 取資料庫資料(問卷說明資料setQuestionnaireInfo，公司重大議題清單setTopics)
  useEffect(() => {
    getMaterialtopicslist();
    getStackholders();
    getQuestionnaireinfot();
  }, []);
  const getQuestionnaireinfot = () => {
    api
      .get("/questionnaireinfot/" + props["project"]["id"])
      .then((response) => {
        console.log(response.data);
        setCorName(response.data.corporateName);
        // setQinfo(response.data.questionnaireInfo);
        setEditContacts(response.data.questionnaireInfo.contacts);
        setEditEmail(response.data.questionnaireInfo.Email);
        const defaultStartTime = response.data.questionnaireInfo.openTime;
        const defaultEndTime = response.data.questionnaireInfo.closeTime;
        setEditTime([defaultStartTime, defaultEndTime]);
      })
      .catch((error) => console.error("Error fetching data:", error));
  };
  {
    console.log(editContacts);
  }
  const getMaterialtopicslist = () => {
    api
      .get("/newmaterialtopicslist/" + props["project"]["id"])
      .then((response) => {
        // console.log(response.data);
        setTopics(response.data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const getStackholders = () => {
    api
      .get("/stackholders/" + props["project"]["id"])
      .then((response) => {
        console.log(response.data);
        setStackholders(response.data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const onChangeRadio = (topicId, e) => {
    // 首先，检查当前议题的选择是否已经存在于状态中
    const existingIndex = topicsRadios.findIndex(
      (radio) => radio.topicsID === topicId
    );
    if (existingIndex >= 0) {
      // 如果已存在，更新该议题的选项
      const updatedRadios = [...topicsRadios];
      updatedRadios[existingIndex] = {
        topicsID: topicId,
        topicsRadios: e.target.value,
      };
      setTopicsRadios(updatedRadios);
    } else {
      // 如果不存在，添加新的选择到状态中
      setTopicsRadios([
        ...topicsRadios,
        { topicsID: topicId, topicsRadios: e.target.value },
      ]);
    }
  };
  const onChangeRadio2 = (topicId, e, impactType) => {
    const newValue = {
      topicsID: topicId,
      topicsRadios: e.target.value,
      impactType: impactType, // 添加了一个新的字段来标识衝擊程度的类型
    };

    const existingIndex = topicsRadios.findIndex(
      (item) => item.topicsID === topicId && item.impactType === impactType
    );

    if (existingIndex >= 0) {
      // 如果已存在相同话题和相同衝擊程度的评分，则更新该项
      const updatedRadios = [...topicsRadios];
      updatedRadios[existingIndex] = newValue;
      setTopicsRadios(updatedRadios);
    } else {
      // 否则，添加新的项
      setTopicsRadios([...topicsRadios, newValue]);
    }
  };
  // 複製網址
  const copyToClipboard = (url) => {
    try {
      navigator.clipboard.writeText(url);
      message.success("網址以複製到剪貼簿");
    } catch (err) {
      message.error("複製失敗，請手動複製");
    }
  };

  const handleSave = () => {
    let data = new FormData();
    console.log(props["project"]["id"]);
    data.append("projectid", props["project"]["id"]);
    data.append("contacts", editContacts);
    data.append("Email", editEmail);
    data.append("openTime", editTime[0]);
    data.append("closeTime", editTime[1]);

    api
      .post("/updatequestionnaireinfo", data)
      .then((response) => {
        console.log(response);
        apinot["success"]({
          message: "通知",
          description: "成功",
        });
        // setIsEditing(false);
        getQuestionnaireinfot();
      })
      .catch((error) => {
        message.error("失敗");
        console.error("Error updating contact info:", error);
      });
  };

  const handleSendOk = () => {
    let saveData = new FormData();
    console.log(props["project"]["id"]);
    saveData.append("projectid", props["project"]["id"]);
    saveData.append("state", "完成問卷");
    handleSave();
    api.patch("/projects", saveData).then((result) => {
      if (result.status === 200) {
        // setIsEditable(false);
        props.setIsEditable("完成問卷");
        console.log(result);
        apinot["success"]({
          message: "通知",
          description: "成功送出",
        });
      }
    });
    setIsSendModalOpen(false);
  };
  const handleCancel = () => {
    setIsSendModalOpen(false);
  };
  const handleSend = () => {
    setIsSendModalOpen(true);
  };
  const onOktime = (value) => {
    if (value && value.length === 2) {
      const formattedStartTime =
        value[0] && value[0].isValid()
          ? value[0].format("YYYY-MM-DD HH:mm:ss")
          : null;
      const formattedEndTime =
        value[1] && value[1].isValid()
          ? value[1].format("YYYY-MM-DD HH:mm:ss")
          : null;
      if (formattedStartTime && formattedEndTime) {
        setEditTime([formattedStartTime, formattedEndTime]);
      } else {
        console.log("Invalid date range selected.");
      }
    }
  };
  return (
    <>
      {contextHolder}
      <div className="questionnaire-container">
        <h2 className="h2title">關注程度/衝擊程度問卷調查</h2>
        <div className="button-div">
          <Segmented
            options={[
              "利害關係人對重大議題的關注程度",
              "組織對重大議題的衝擊程度",
            ]}
            onChange={(value) => {
              setShowImpact(!showImpact);
              console.log(showImpact);
            }}
          />
        </div>{" "}
        {console.log(editTime)}
        <div style={{ margin: "2%" }}>
          請輸入表單開放時間：
          <RangePicker
            showTime={{
              format: "HH:mm",
            }}
            format="YYYY-MM-DD HH:mm"
            // defaultValue={editTime}
            disabled={!isEditable}
            value={[
              editTime[0] === undefined
                ? moment("2024-05-03 00:00:00.000000", "YYYY-MM-DD HH:mm")
                : moment(editTime[0], "YYYY-MM-DD HH:mm"),
              editTime[1] === undefined
                ? moment("2024-05-04 00:00:00.000000", "YYYY-MM-DD HH:mm")
                : moment(editTime[1], "YYYY-MM-DD HH:mm"),
            ]}
            onOk={onOktime}
          />
        </div>
        <div className="outquestionnaire-container">
          <h2 className="h2">{corName} 重大議題關注程度問卷</h2>
          <div  className="text-div">
            <div className="questionnaire-intro">
              <div className="questionnaire-section">
                各位利害關係人您好！
                <br />
                我們是{corName}
                ，致力於在我們的業務中貫徹可持續發展和社會責任。為了更好地理解和響應利害關係人的期望與需求，我們正在進行一項關於重大議題關注程度的調查。您的見解對我們至關重要，將直接影響我們未來的戰略規劃和行動方向。
              </div>
              <div className="questionnaire-section">
                本問卷旨在收集您對於我們識別出的重大議題的關注程度。我們將這些議題分為多個領域，如環境保護、社會責任和公司治理等。針對每個議題，我們希望了解您認為其重要性的程度。{" "}
              </div>
              <div className="questionnaire-contact">
                問卷大約需要您 5-10
                分鐘的時間來完成。請您根據實際情況選擇對應的選項。如果您在填寫問卷過程中遇到任何問題，或希望與我們進一步溝通，歡迎通過以下方式聯繫我們：{" "}
                <br />
                <br />
                <div className="contactPerson">
                  <div> 聯絡人：</div>
                  <div>
                    <Input
                      disabled={!isEditable}
                      value={editContacts}
                      onChange={(e) => setEditContacts(e.target.value)}
                    />
                  </div>
                  <div style={{ marginLeft: "3%" }}>電子信箱：</div>
                  <div>
                    <Input
                      disabled={!isEditable}
                      value={editEmail}
                      onChange={(e) => setEditEmail(e.target.value)}
                    />
                  </div>
                </div>
                {/* <div>
                  {isEditable && (
                    <Button
                      onClick={() =>
                        isEditing ? saveContacts() : setIsEditing(true)
                      }
                    >
                      {isEditing ? "保存" : "編輯聯絡人"}
                    </Button>
                  )}
                </div> */}
              </div>
            </div>
          </div>
          <h2 className="h3">
            針對下列議題，請依據您的{showImpact ? "衝擊程度" : "關注程度"}
            進行填寫：
          </h2>
          {showImpact ? (
            impactDescriptions.map((description, index) => (
              <>
                <div className="form-block">{description}</div>
                <div className="form-block-radio">
                  <Row className="topic-header">
                    <Col  className="topic-column" span={3}>
                      面相
                    </Col>
                    <Col  className="topic-column" span={3}>
                      重大議題
                    </Col>
                    <Col  className="topic-column" span={12}>
                      定義與說明
                    </Col>
                    <Col  className="topic-column" span={6}>
                      {showImpact ? "衝擊程度" : "關注程度"}
                    </Col>
                  </Row>
                  {topics.map((topic) => (
                    <Row key={topic.id} className="topic-row">
                      {/* Column 內容，根據需求調整 */}
                      <Col span={3} className="topic-column">
                        {topic.physiognomy}
                      </Col>
                      <Col span={3} className="topic-column">
                        {topic.materialTopics}
                      </Col>
                      {/* 這裡插入一個額外的 Column 來顯示當前的衝擊程度描述 */}
                      <Col span={12} className="topic-column">
                        {topic.description}
                      </Col>
                      <Col span={6} className="topic-column">
                        <Radio.Group
                          disabled={true}
                          // onChange={(e) =>
                          //   onChangeRadio2(topic.id, e, description)
                          // }
                          value={
                            topicsRadios.find(
                              (radio) =>
                                radio.topicsID === topic.id &&
                                radio.impactType === description
                            )?.topicsRadios
                          }
                        >
                          <Radio value={1}>1</Radio>
                          <Radio value={2}>2</Radio>
                          <Radio value={3}>3</Radio>
                          <Radio value={4}>4</Radio>
                          <Radio value={5}>5</Radio>
                        </Radio.Group>
                      </Col>
                    </Row>
                  ))}
                </div>
                {/* </div> */}
              </>
            ))
          ) : (
            <div  className="form-block-radio">
              <Row  className="topic-header">
                <Col  className="topic-column" span={3}>
                  面相
                </Col>
                <Col  className="topic-column" span={3}>
                  重大議題
                </Col>
                <Col  className="topic-column" span={12}>
                  定義與說明
                </Col>
                <Col  className="topic-column" span={6}>
                  {showImpact ? "衝擊程度" : "關注程度"}
                </Col>
              </Row>
              {topics.map((topic) => (
                <Row key={topic.id} className="topic-row">
                  <Col span={3} className="topic-column">
                    {topic.physiognomy}
                  </Col>
                  <Col span={3} className="topic-column">
                    {topic.materialTopics}
                  </Col>
                  <Col span={12} className="topic-column">
                    {topic.description}
                  </Col>
                  <Col span={6} className="topic-column">
                    <Radio.Group
                      disabled={true}
                      // onChange={(e) => onChangeRadio(topic.id, e)}
                      value={
                        topicsRadios.find(
                          (radio) => radio.topicsID === topic.id
                        )?.topicsRadios
                      }
                    >
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Col>
                </Row>
              ))}
            </div>
          )}
        </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "90%",
            }}
          >
            {stackholders.map((stackholder) => (
              <Row
                style={{
                  width: "70%",
                  margin: "2% auto",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Col span={4}>{stackholder.identity}</Col>
                <Col span={16}>
                  <Space.Compact block>
                    <Input
                      value={
                        "https://esggggggggg.me/OutQuestionnaire?pid=" +
                        props["project"]["id"] +
                        "&s=" +
                        showImpact +
                        "&sid=" +
                        stackholder.id
                      }
                      readOnly
                    />
                    <Tooltip title="copy git url">
                      <Button
                        icon={<CopyOutlined />}
                        onClick={() =>
                          copyToClipboard(
                            "https://esggggggggg.me/OutQuestionnaire?pid=" +
                              props["project"]["id"] +
                              "&s=" +
                              showImpact +
                              "&sid=" +
                              stackholder.id
                          )
                        }
                      />
                    </Tooltip>
                  </Space.Compact>
                </Col>
              </Row>
            ))}
          </div>
        {/* )} */}
        {isEditable && (
          <div style={{ display: "flex", margin: "2%" }}>
            <Button
              style={{ margin: "1%" }}
              type="primary"
              onClick={handleSave}
              icon={<SaveOutlined />}
            >
              儲存
            </Button>
            <Button
              type="primary"
              style={{ margin: "1%" }}
              onClick={handleSend}
              icon={<SendOutlined />}
            >
              送出 (送出後不得修改)
            </Button>
          </div>
        )}
      </div>
      <Modal
        title="是否送出"
        open={isSendModalOpen}
        onOk={handleSendOk}
        onCancel={handleCancel}
      >
        <p>送出後不得修改</p>
      </Modal>
    </>
  );
};

export default Questionnaire;
