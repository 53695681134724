import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const MatrixChart = ({ data }) => {
  // console.log(data);
  const chartRef = useRef(null);
  // 使用 useRef 来持有图表实例的引用
  const chartInstanceRef = useRef(null);

  const chartData = {
    datasets: [
      {
        label: "重大議題",
        data: data.map((item) => ({
          x: item.concern_level,
          y: item.impact_level,
          r: Math.pow(item.total_score, 2) * 2, // 使用平方增加差异
          topic_name: item.topic_name, // 嵌入 topic_name
        })),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");

    // 如果已经有了图表实例，先销毁它
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }
    // 创建新的图表实例
    chartInstanceRef.current = new Chart(ctx, {
      type: "bubble",
      data: chartData,

      options: {
        maintainAspectRatio: false,
        scales: {
          x: {
            min: 0,
            max: 5,
            title: {
              display: true,
              text: "利害關係人對議題的關注程度",
            },
          },
          y: {
            min: 0,
            max: 5,
            title: {
              display: true,
              text: "議題對公司的影響程度",
            },
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                const dataPoint = context.raw;
                return `Topic: ${dataPoint.topic_name}`;
              },
            },
          },
        },
      },
    });

    // 在组件卸载时销毁图表实例
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [data]); // 依赖项 data 发生变化时重新渲染图表

  return <canvas ref={chartRef}></canvas>;
};

export default MatrixChart;
