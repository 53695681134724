import React, { useState, useEffect } from "react";
import "../../style/identification.scss";
import {
  Button,
  Input,
  Row,
  Slider,
  Col,
  InputNumber,
  Modal,
  notification,
} from "antd";
import {
  PlusOutlined,
  DeleteTwoTone,
  SaveOutlined,
  SendOutlined,
} from "@ant-design/icons";
import api from "../../components/AxiosDefault";

const Identification = (props) => {
  const isEditable = props.isEditable;
  const [apinot, contextHolder] = notification.useNotification();
  const [newIdentity, setNewIdentity] = useState("");
  // const [isEditable, setIsEditable] = useState(true);

  const [isSendModalOpen, setIsSendModalOpen] = useState(false); // 使否顯示新增Modal
  const [showModal, setShowModal] = useState(false); // 使否顯示新增Modal
  const [stakeholdersData, setStakeholdersData] = useState([
    {
      id: 0,
      identity: "",
      importance: 0,
      significance: "",
    },
  ]);
  const [allStakeholders, setAllStakeholders] = useState([
    // 儲存選定的利益關係人的狀態
    { id: 1, name: "股東", checked: false, rank: null },
    { id: 2, name: "客戶", checked: false, rank: null },
    { id: 3, name: "員工", checked: false, rank: null },
    { id: 4, name: "社區居民/地方團體", checked: false, rank: null },
    { id: 5, name: "供應商/承攬商", checked: false, rank: null },
    { id: 6, name: "政府機關", checked: false, rank: null },
    { id: 7, name: "學術研究人員", checked: false, rank: null },
    { id: 8, name: "非營利組織", checked: false, rank: null },
    { id: 9, name: "媒體記者", checked: false, rank: null },
    // 更多利益關係人資料
  ]);

  const [inputValue, setInputValue] = useState(1);

  useEffect(() => {
    // props["project"]["state"] === "完成利害關係人鑑別" &&
    //   props.setIsEditable("完成利害關係人鑑別");

    api
      .get("/stackholders/" + props["project"]["id"])
      .then((response) => {
        console.log(response.status);
        setStakeholdersData(response.data);
        const updatedAllStakeholders = allStakeholders.map((stakeholder) => {
          const isStakeholderChecked = response.data.some(
            (data) => data.identity === stakeholder.name
          );
          return {
            ...stakeholder,
            checked: isStakeholderChecked,
          };
        });
        setAllStakeholders(updatedAllStakeholders);
      })
      .catch((error) => console.error("Error fetching data:", error));
    // 補判斷是否原本就要打勾
  }, []);
  const onChange = (newValue) => {
    setInputValue(newValue);
  };

  // 處理勾選框變化事件
  const handleCheckboxChange = (id) => {
    const updatedStakeholders = allStakeholders.map((stakeholder) =>
      stakeholder.id === id
        ? { ...stakeholder, checked: !stakeholder.checked }
        : stakeholder
    );
    setAllStakeholders(updatedStakeholders);

    // 根据 checked 状态添加或删除 stakeholdersData 中的数据
    const changedStakeholder = updatedStakeholders.find(
      (stakeholder) => stakeholder.id === id
    );
    if (changedStakeholder.checked) {
      // 如果是被勾选，添加数据到 stakeholdersData
      const newStakeholderData = {
        id:
          stakeholdersData.length > 0
            ? Math.max(...stakeholdersData.map((data) => data.id)) + 1
            : 1,
        identity: changedStakeholder.name,
        projectid: props["project"]["id"],
        importance: 1, // 可以设置默认重要度
        significance: "", // 默认意义为空
      };
      setStakeholdersData([...stakeholdersData, newStakeholderData]);
    } else {
      // 如果是取消勾选，从 stakeholdersData 删除数据
      const filteredStakeholdersData = stakeholdersData.filter(
        (data) => data.identity !== changedStakeholder.name
      );
      setStakeholdersData(filteredStakeholdersData);
    }
  };

  // 刪除利害關係人
  const handledelete = (id) => {
    // 查找需要删除的stakeholdersData
    const deletedData = stakeholdersData.find((data) => data.id === id);
    // 從stakeholdersData刪除對應的資料
    const filteredStakeholdersData = stakeholdersData.filter(
      (data) => data.id !== id
    );
    setStakeholdersData(filteredStakeholdersData);

    // 如果删除的条目在allStakeholders的name中存在，取消勾選
    const stakeholderIndex = allStakeholders.findIndex(
      (stakeholder) => stakeholder.name === deletedData.identity
    );
    if (stakeholderIndex !== -1) {
      const updatedStakeholders = [...allStakeholders];
      updatedStakeholders[stakeholderIndex] = {
        ...updatedStakeholders[stakeholderIndex],
        checked: false,
      };
      setAllStakeholders(updatedStakeholders);
    }
  };
  // 處理新增自定義內容
  const addCustomStakeholder = () => {
    // 創建一個新的利害關係人對象
    const newStakeholder = {
      id:
        stakeholdersData.length > 0
          ? stakeholdersData[stakeholdersData.length - 1].id + 1
          : 1, // 確保id是唯一的
      identity: "", // 初始身份為空，等待用戶輸入
      significance: "", // 初始意義為空，等待用戶輸入
      importance: 1, // 初始重要度
    };

    // 更新state以加入新的利害關係人
    setStakeholdersData([...stakeholdersData, newStakeholder]);
  };
  const handleshowModal = () => {
    setShowModal(true);
  };
  const handleOk = () => {
    const isIdentityExist = stakeholdersData.some(
      (stakeholder) => stakeholder.identity === newIdentity
    );
    const allStakeholdersIndex = allStakeholders.findIndex(
      (stakeholder) => stakeholder.name === newIdentity.trim()
    );
    if (!isIdentityExist && newIdentity.trim() !== "") {
      // identity不存在于allStakeholders中，可以添加
      const newStakeholder = {
        id:
          stakeholdersData.length > 0
            ? Math.max(...stakeholdersData.map((data) => data.id)) + 1
            : 1,
        identity: newIdentity,
        importance: 1, // 默认重要度
        significance: "", // 默认意义为空
        // isCheckboxAdded: false, // 表示这是一个自定义条目
      };
      setStakeholdersData([...stakeholdersData, newStakeholder]);
      setShowModal(false);
      setNewIdentity("");
      // 如果存在于allStakeholders但未勾选，则勾选起来
      if (
        allStakeholdersIndex !== -1 &&
        !allStakeholders[allStakeholdersIndex].checked
      ) {
        const updatedAllStakeholders = [...allStakeholders];
        updatedAllStakeholders[allStakeholdersIndex] = {
          ...updatedAllStakeholders[allStakeholdersIndex],
          checked: true,
        };
        setAllStakeholders(updatedAllStakeholders);
      }
    } else if (newIdentity.trim() === "") {
      alert("請輸入有效的利害關係人名稱!");
    } else {
      alert("該利害關係人已存在，請輸入不同的名稱!");
    }
  };

  const handleCancel = () => {
    setShowModal(false);
    setIsSendModalOpen(false);
  };

  const handleSave = () => {
    console.log(JSON.stringify(stakeholdersData));
    api
      .post("/stackholders/" + props["project"]["id"], stakeholdersData)
      .then((result) => {
        console.log(result.status);
        if (result.status === 201) {
          apinot["success"]({
            message: "通知",
            description: "儲存成功",
          });
        }
      })
      .catch((error) => console.error("Error fetching data:", error)); // let saveData = new FormData();
  };

  const handleSendOk = () => {
    let saveData = new FormData();
    console.log(props["project"]["id"]);
    saveData.append("projectid", props["project"]["id"]);
    saveData.append("state", "完成利害關係人鑑別");
    handleSave();
    api.patch("/projects", saveData).then((result) => {
      if (result.status === 200) {
        // setIsEditable(false);
        props.setIsEditable("完成利害關係人鑑別");
        console.log(result);
        apinot["success"]({
          message: "通知",
          description: "成功送出",
        });
      }
    });
    setIsSendModalOpen(false);
  };
  const handleSend = () => {
    setIsSendModalOpen(true);
  };

  return (
    <>
      {contextHolder}
      <div className="stakeholdercontainer">
        <h2 className="h2title">鑑別利害關係人</h2>
        <div className="stakeholderlist">
          {/* 利害關係人勾選框 */}
          {allStakeholders.map((stakeholder) => (
            <label key={stakeholder.id} className="stakeholderitem">
              <input
                disabled={!isEditable}
                type="checkbox"
                checked={stakeholder.checked}
                onChange={() => handleCheckboxChange(stakeholder.id)}
              />
              {stakeholder.name}
            </label>
          ))}
        </div>
        <div>
          <Row className="row-div">
            <Col span={3}>利害關係人</Col>
            <Col span={12}>利害關係人對組織意義</Col>
            <Col span={6}>重要度</Col>
            {isEditable && <Col span={2}>刪除</Col>}
          </Row>
          {stakeholdersData.map((data, key) => (
            <Row key={key} className="row-map">
              <Col span={3}>{data.identity}</Col>
              <Col span={12}>
                {/* {data.significance} */}
                <Input
                  disabled={!isEditable}
                  value={data.significance}
                  style={{ width: "90%" }}
                  onChange={(e) => {
                    const newData = [...stakeholdersData];
                    newData[key] = { ...data, significance: e.target.value };
                    setStakeholdersData(newData);
                  }}
                />
              </Col>
              <Col span={4}>
                <Slider
                  disabled={!isEditable}
                  min={1}
                  max={10}
                  // onChange={onChange}
                  onChange={(value) => {
                    // 创建一个新数组来更新当前项目的 importance
                    const newData = [...stakeholdersData];
                    newData[key] = { ...data, importance: value };
                    setStakeholdersData(newData);
                  }}
                  value={
                    typeof data.importance === "number" ? data.importance : 0
                  }
                  style={{
                    margin: "0 16px",
                  }}
                />
              </Col>
              <Col span={2}>
                <InputNumber
                  disabled={!isEditable}
                  min={1}
                  max={10}
                  style={{
                    margin: "0 16px",
                    width: "80%",
                  }}
                  value={data.importance}
                  // onChange={onChange}
                  onChange={(value) => {
                    // 与 Slider 相同的处理，更新 importance
                    const newData = [...stakeholdersData];
                    newData[key] = { ...data, importance: value };
                    setStakeholdersData(newData);
                  }}
                />
              </Col>{" "}
              {isEditable && (
                <Col span={2}>
                  <DeleteTwoTone
                    twoToneColor="#ff2929"
                    style={{ fontSize: "18px" }}
                    onClick={() => handledelete(data.id)}
                  />
                </Col>
              )}
            </Row>
          ))}
        </div>
        {isEditable && (
          <>
            <div style={{ marginBottom: "3%" }}>
              <Button
                type="default"
                onClick={handleshowModal}
                icon={<PlusOutlined />}
              >
                添加自定義利害關係人
              </Button>
            </div>
            <div>
              <Button
                style={{ margin: "1%" }}
                type="primary"
                onClick={handleSave}
                icon={<SaveOutlined />}
              >
                儲存
              </Button>
              <Button
                type="primary"
                style={{ margin: "1%" }}
                onClick={handleSend}
                icon={<SendOutlined />}
              >
                送出 (送出後不得修改)
              </Button>
            </div>
          </>
        )}
      </div>
      <Modal
        title="添加自定義利害關係人"
        open={showModal}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Input
          value={newIdentity}
          onChange={(e) => setNewIdentity(e.target.value)}
          placeholder="請輸入利害關係人名稱"
        />
      </Modal>
      <Modal
        title="是否送出"
        open={isSendModalOpen}
        onOk={handleSendOk}
        onCancel={handleCancel}
      >
        <p>送出後不得修改</p>
      </Modal>
    </>
  );
};

export default Identification;
