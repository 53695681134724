import React, { useState, useEffect } from "react";
import "../../style/Majorpic.scss";
import api from "../../components/AxiosDefault";
import MatrixChart from "../../components/MatrixChart";

const Majorpic = (props) => {
  const [data, setData] = useState([]); // 儲存關注程度排行榜的資料

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    api
    .post("/TopicImpactView/" + props["project"]["id"] + "/")
    .then((response) => {
        console.log(response.data);
        setData(response.data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  return (
    <div className="h2title">
      <div>
        <h2 className="h2title">重大議題矩陣圖</h2>
        <div className="chart">
          <MatrixChart data={data} />
        </div>
      </div>
    </div>
  );
};

export default Majorpic;
