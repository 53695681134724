import React, { useState } from "react";
import { Button, Input, Form, Modal, notification, Spin } from "antd";
import "../style/loginpage.scss";
import { useNavigate } from "react-router-dom";
import { path_server } from "../components/Constants";

const Loginpage = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  // const onFinish = (values) => {
  //   console.log("Success:", values);
  //   navigate("/");
  // };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  console.log(path_server);
  const onFinish = () => {
    fetch(path_server+'/token/', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    })
      .then(async (response) => {
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          const { access, refresh } = data;
          // 在這裡處理獲取到的access token和refresh token存儲在本地
          localStorage.setItem("access_token", access);
          localStorage.setItem("refresh_token", refresh);
          navigate("/Home");
        } else {
          console.error("登入失敗");
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  };
  return (
    <div className="longinpage">
      <div className="item">
        <div className="title">ESG Report</div>
        <div className="title2">Login</div>

        <div className="form">
          <Form
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            style={{
              maxWidth: 600,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Username"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your username!",
                },
              ]}
              onChange={(e) => setUsername(e.target.value)}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
              onChange={(e) => setPassword(e.target.value)}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                登入
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default Loginpage;
