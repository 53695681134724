import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Input, Form, Modal, notification, Spin } from "antd";
import { Editor, EditorState, convertFromHTML, ContentState } from "draft-js";
import RichTextEditor from "../components/richTextEditor.js";
import "../style/report.scss";
import api from "../components/AxiosDefault";
import { jwtDecode } from "jwt-decode";

const Report = (props) => {
  let location = useLocation();
  const record = location.state.record;
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [dataC, setDataC] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false); //是否顯示modal對話框
  const [editContentStatus, setEditContentStatus] = useState(false); //是否編輯狀態
  const [content, setContent] = useState(record.content); //初始content
  const [isLoading, setLoading] = useState(false); //是否等待生成資料
  const [genContent, setgenContent] = useState("");
  const decoded = jwtDecode(localStorage.getItem("access_token"));

  // 取資料庫資料
  const getsetWTData = () => {
    let WTData = new FormData();
    WTData.append("corporateId", decoded["corporateId"]);
    WTData.append("topicId", record.id);

    api
      .post("/economicPerformance", WTData)
      .then((result) => {
        setData(result.data);
      })
      .catch((error) => console.error("Error fetching data:", error));
    api
      .post("/economicPerformanceC", WTData)
      .then((result) => {
        console.log(result.data);
        setDataC(result.data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  };
  useEffect(() => {
    getsetWTData();
  }, []);

  // 填完資料，生成內容
  const handlegetdata = (values) => {
    console.log(values);
    setLoading(true);
    // 整理回答的資料
    let genData = new FormData();
    genData.append("corporateId", decoded["corporateId"]);
    genData.append("topicId", record.id);
    genData.append("topicdata", JSON.stringify(values));

    api.patch("/assistants", genData).then((result) => {
      console.log(result.data.response);
      setgenContent(result.data.response);
      setLoading(false);
      setShowEditModal(true);
    });

    console.log(values);
  };
  // 取消編輯
  const onCancel = (e) => {
    setShowEditModal(false);
  };
  const onOK = (e) => {
    setShowEditModal(false);
  };

  // 上方編輯
  const editContent = () => {
    setEditContentStatus(true);
  };
  // 編輯儲存內容
  const editSaveContent = (Contenthtml) => {
    // console.log(Contenthtml);
    let contentData = new FormData();
    contentData.append("content", Contenthtml);
    api
      .patch("/writeTopic/" + record.id, contentData)
      .then((result) => setContent(result.data.content))
      .catch((error) => console.error("Error fetching data:", error));
    setEditContentStatus(false);
    setShowEditModal(false);
  };

  // 編輯取消內容
  const editCancelContent = () => {
    setEditContentStatus(false);
    setShowEditModal(false);
  };
  return (
    <>
      <Spin spinning={isLoading} fullscreen />
      <Modal
        // forceRender
        title="生成文本"
        open={showEditModal}
        okText="存檔"
        cancelText="取消"
        onOk={onOK}
        onCancel={onCancel}
        destroyOnClose={true}
        width={1200}
        footer={null} // 设置footer为null，以隐藏默认的底部按钮
      >
        <RichTextEditor
          initialHtml={genContent}
          editSaveContent={editSaveContent}
          editCancelContent={editCancelContent}
        />
      </Modal>
      <div className="report">
        <div className="title">{record.materialTopics}</div>
        <div className="title2">{record.description}</div>
        <div className="content">
          {/* html內容顯示 */}
          {content ? (
            editContentStatus ? (
              <>
                <RichTextEditor
                  initialHtml={content}
                  editSaveContent={editSaveContent}
                  editCancelContent={editCancelContent}
                />
              </>
            ) : (
              <>
                <Editor
                  editorState={EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                      // contentHTML.contentBlocks,
                      // contentHTML.entityMap
                      convertFromHTML(content).contentBlocks,
                      convertFromHTML(content).entityMap
                    )
                  )}
                  readOnly={true}
                />{" "}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button onClick={editContent} style={{ marginTop: "2%" }}>
                    編輯
                  </Button>
                </div>
              </>
            )
          ) : (
            "暫無內容"
          )}
        </div>
        {/* {Object.keys(data).map((keyName, i) => (
        <li key={i}>
          {console.log(keyName)}
          key: {keyName} Name: {data[keyName]}
        </li>
      ))} */}
        <div className="title3">輸入以下資料，可生成內容</div>
        <div className="form">
          <Form
            name="economicPerformance-form"
            form={form}
            layout="vertical"
            onFinish={handlegetdata}
            // labelCol={{ span: 4 }}
            // wrapperCol={{ spane: 20 }}
            // initialValues={{
            //   headquarters: data.headquarters,
            //   industry: data.industry,
            //   employeeCount: data.employeeCount,
            //   established: data.established,
            //   companyProfile: data.companyProfile,
            //   overview: data.overview,
            // }}
          >
            {dataC.map((data, key) => {
              if (data["COLUMN_COMMENT"])
                return (
                  <Form.Item
                    key={key}
                    label={data["COLUMN_COMMENT"]}
                    name={data["COLUMN_NAME"]}
                    hasFeedback
                    // rules={[{ required: true, message: "請填寫" + { keyName } }]}
                  >
                    <Input.TextArea autoSize={{ minRows: 2, maxRows: 5 }} />
                  </Form.Item>
                );
            })}
            <Form.Item>
              <div className="button">
                <Button type="primary" htmlType="submit">
                  生成內容
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};
export default Report;
