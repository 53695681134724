import React, { useState, useEffect } from "react";
import { Button } from "antd";
import api from "../components/AxiosDefault";
import { jwtDecode } from "jwt-decode";

const Records = (props) => {
  const [content, setContent] = useState(); //初始content
  const decoded = jwtDecode(localStorage.getItem("access_token"));
  useEffect(() => {
    let corporateData = new FormData();
    corporateData.append("corporateId", decoded["corporateId"]);
    api
      .post("/getcorprojects", corporateData)
      .then((result) => {
        // const formattedProjects = result.data.map((project) => ({
        //   value: project.id.toString(), // 将 id 转换为字符串
        //   label: project.projectName, // 使用 projectName 作为 label
        // }));
        setContent(result.data);
        console.log(result.data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);
  const pdfd = (projectid) => {
    let saveData = new FormData();
    saveData.append("projectid", projectid);
    api
      .post("/pdf", saveData, { responseType: "blob" })
      .then((response) => {
        console.log(response);
        if (response.status !== 200) {
          throw new Error("Network response was not ok");
        }
        return response.data;
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "ESGReport.pdf");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  return (
    <div style={{ width: "70%", margin: "2%", display: "flex" }}>
      {content ? (
        content.map((project) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "20vh",
              height: "30vh",
              alignItems: "center",
              margin: "1%",
            }}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
                background: "#507AB8",
                textAlign: "center",
                alignContent: "center",
                borderRadius: "10px",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px;",
                fontSize: "18px",
                fontWeight: "bold",
                color: "#fff",
              }}
            >
              {project.projectName}
            </div>
            <Button
              style={{ width: "50%", margin: "5%" }}
              onClick={() => {
                pdfd(project.id);
              }}
            >
              下載
            </Button>
          </div>
        ))
      ) : (
        <div></div>
      )}
    </div>
  );
};
export default Records;
