import React from "react";
import Home from "./views/Home";
import Information from "./views/information";
import StakeholderProject from "./views/stakeholderProject";
import Stakeholder from "./views/stakeholder";
import ReportList from "./views/reportList";
import Report from "./views/report";
import Records from "./views/records";
import Loginpage from "./views/loginpage";
import OutQuestionnaire from "./views/outQuestionnaire";

// import { useLocation } from "react-router-dom";

// import { Link, Route, Routes } from "react-router-dom";
import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { LogoutOutlined } from "@ant-design/icons";
import {
  HomeOutlined,
  FundProjectionScreenOutlined,
  TeamOutlined,
  BankOutlined,
  ReadOutlined,
} from "@ant-design/icons";
import { Layout, Menu, theme } from "antd";
const { Header, Content, Sider } = Layout;

const itemsList = [
  {
    key: "/Home",
    label: (
      <>
        <Link to="/Home">首頁</Link>
      </>
    ),
    icon: <HomeOutlined />,
  },
  {
    key: "/Information",
    label: (
      <>
        <Link to="/Information">公司基本資料</Link>
      </>
    ),
    icon: <BankOutlined />,
  },
  {
    key: "/StakeholderProject",
    label: (
      <>
        <Link to="/StakeholderProject">利害關係人議合</Link>
      </>
    ),
    icon: <TeamOutlined />,
  },
  {
    key: "/ReportList",
    label: (
      <>
        <Link to="/ReportList">報告書製作</Link>
      </>
    ),
    icon: <ReadOutlined />,
  },
  {
    key: "/Records",
    label: (
      <>
        <Link to="/Records">紀錄/歷史資料</Link>
      </>
    ),
    icon: <FundProjectionScreenOutlined />,
  },
];

const App = (props) => {
  const navigate = useNavigate();

  let location = useLocation();
  let showLayout =
    location.pathname !== "/loginpage" &&
    location.pathname !== "/OutQuestionnaire" &&
    location.pathname !== "/";
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Layout style={{ minHeight: "100vh", margin: "0", padding: "0" }}>
      {showLayout && (
        <Header
          style={{
            display: "flex",
            alignItems: "center",
            background: "#6FBCC7",
            boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
            position: "sticky",
            justifyContent: "space-between", // 使内容分散对齐，左端为标题，右端为图标
            // position: 'sticky',
            top: 0,
            zIndex: 2,
            width: "100%",
            // display: 'flex',
            // alignItems: 'center',
          }}
        >
          <div
            style={{
              color: "#FFF",
              fontSize: "26px",
              fontWeight: "bold",
            }}
          >
            ESG Report
          </div>

          <LogoutOutlined
            style={{
              color: "#FFF",
              fontSize: "26px",
              fontWeight: "bold",
            }}
            onClick={() => {
              window.localStorage.removeItem("access_token");
              window.localStorage.removeItem("refresh_token");
              navigate("/loginpage");
            }}
          />
        </Header>
      )}

      <Layout>
        {showLayout && (
          <Sider
            width={200}
            style={{
              background: "#fff",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              overflow: "auto",
              // height: "100vh",
              position: "fixed",
              // left: 0,
              top: 0,
              bottom: 0,
              marginTop: 64,
            }}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              style={{ height: "100%", borderRight: 0 }}
              items={itemsList}
            />
          </Sider>
        )}
        <Layout>
          <Content
            style={{ background: "#FFF", marginLeft: showLayout ? 200 : 0 }}
          >
            <Routes>
              <Route path="/Home" element={<Home />} />
              <Route path="/Information" element={<Information />} />
              <Route
                path="/StakeholderProject"
                element={<StakeholderProject />}
              />
              <Route path="/Stakeholder" element={<Stakeholder />} />
              <Route path="/ReportList" element={<ReportList />} />
              <Route path="/Report" element={<Report />} />
              <Route path="/Records" element={<Records />} />
              <Route path="/OutQuestionnaire" element={<OutQuestionnaire />} />
              <Route path="/loginpage" element={<Loginpage />} />

              <Route
                path="/"
                element={
                  window.localStorage.getItem("access_token") ? (
                    <Navigate to="/Home" />
                  ) : (
                    <Loginpage />
                  )
                }
              />
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
export default App;
