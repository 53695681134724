import React, { useState, useEffect } from "react";
import api from "../components/AxiosDefault";
import "../style/questionnaire.scss";
import { SendOutlined } from "@ant-design/icons";
import { Button, Radio, Spin, message, Row, Col } from "antd";
const OutQuestionnaire = () => {
  const [level, setLevel] = useState([]);
  const [showImpact, setShowImpact] = useState(false);

  const [stackholderid, setStackholderid] = useState();
  const [projectid, setProjectid] = useState();
  const [qinfo, setQinfo] = useState(); //問卷資料
  const [isLoading, setIsLoading] = useState(true);
  const [hasSubmitted, setHasSubmitted] = useState(false); //問卷資料
  const impactDescriptions = [
    "正面潛在衝擊議題的程度",
    "正面實際衝擊議題的程度",
    "負面實際衝擊議題的程度",
    "負面潛在衝擊議題的程度",
  ];
  const [topics, setTopics] = useState([]);
  useEffect(() => {
    const hasSubmitted = localStorage.getItem("hasSubmitted");
    if (hasSubmitted) {
      // message.error("您已經填寫過這份問卷了!");
      setHasSubmitted(true);
      // return;
    }
    const search = window.location.search;
    const params = new URLSearchParams(search);
    getMaterialtopicslist(params.get("pid"));
    setProjectid(params.get("pid"));
    setShowImpact(params.get("s") === "true" ? true : false);
    setStackholderid(params.get("sid"));
    getQuestionnaireinfot();
  }, []);
  const getQuestionnaireinfot = () => {
    api
      .get("/questionnaireinfot/" + projectid)
      .then((response) => {
        console.log(response.data);
        setQinfo(response.data);
        // setCorName(response.data.corporateName);
        // // setQinfo(response.data.questionnaireInfo);
        // setEditContacts(response.data.questionnaireInfo.contacts);
        // setEditEmail(response.data.questionnaireInfo.Email);
        // const defaultStartTime = response.data.questionnaireInfo.openTime;
        // const defaultEndTime = response.data.questionnaireInfo.closeTime;
        // setEditTime([defaultStartTime, defaultEndTime]);
      })
      .catch((error) => console.error("Error fetching data:", error));
  };
  const getMaterialtopicslist = (pid) => {
    setIsLoading(true);
    console.log();
    api
      .get("/newmaterialtopicslist/" + String(pid))
      .then((response) => {
        console.log(response.data);
        setTopics(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching data:", error);
      });
    api
      .get("/questionnaireinfot/" + String(pid))
      .then((response) => {
        console.log(response.data);
        setQinfo(response.data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const onChangeRadio = (topicId, e) => {
    // 检查当前议题的选择是否已经存在于状态中
    const existingIndex = level.findIndex((radio) => radio.topicId === topicId);
    if (existingIndex >= 0) {
      // 如果已存在，更新该议题的选项
      const updatedRadios = [...level];
      updatedRadios[existingIndex] = {
        topicId: topicId,
        level: e.target.value,
      };
      setLevel(updatedRadios);
    } else {
      // 如果不存在，添加新的选择到状态中
      setLevel([...level, { topicId: topicId, level: e.target.value }]);
    }
  };
  const onChangeRadio2 = (topicId, e, impactType) => {
    const newValue = {
      topicId: topicId,
      level: e.target.value,
      impactType: impactType,
    };

    const existingIndex = level.findIndex(
      (item) => item.topicId === topicId && item.impactType === impactType
    );

    if (existingIndex >= 0) {
      // 如果已存在相同话题和相同衝擊程度的评分，则更新该项
      const updatedRadios = [...level];
      updatedRadios[existingIndex] = newValue;
      setLevel(updatedRadios);
    } else {
      // 否则，添加新的项
      setLevel([...level, newValue]);
    }
  };
  const handleSend2 = () => {
    const isAllSelected = topics.every((topic) =>
      impactDescriptions.every((impactType) =>
        level.some(
          (item) => item.topicId === topic.id && item.impactType === impactType
        )
      )
    );
    if (!isAllSelected) {
      // 如果有未選擇的 Radio
      message.error("尚未填寫完畢");
      return; // 中斷執行
    }
    function transformData(data) {
      // 初始化一個 Map 來存儲轉換後的資料，鍵為 topicId
      const transformed = new Map();

      data.forEach(({ topicId, level, impactType }) => {
        // 確保每個 topicId 對應到一個唯一的物件
        if (!transformed.has(topicId)) {
          transformed.set(topicId, { topicId });
        }

        const entry = transformed.get(topicId);
        entry.projectid = projectid;
        entry.stackholderid = stackholderid;

        // 根據 impactType 設置對應的評分
        switch (impactType) {
          case "正面潛在衝擊議題的程度":
            entry.positivepot = level;
            break;
          case "正面實際衝擊議題的程度":
            entry.positiverea = level;
            break;
          case "負面實際衝擊議題的程度":
            entry.negativerea = level;
            break;
          case "負面潛在衝擊議題的程度":
            entry.negativepot = level;
            break;
          default:
            // 處理未知的 impactType
            console.warn(`Unknown impactType: ${impactType}`);
        }
      });

      // 將 Map 轉換為陣列
      return Array.from(transformed.values());
    }

    // 使用 transformData 函數轉換資料
    const finalData = transformData(level);
    console.log(finalData);
    // 存資料庫
    api
      .post("/addImpact", finalData)
      .then((result) => {
        console.log(result.status);
        if (result.status === 201) {
          console.log("成功");
          // 跳轉畫面
          setHasSubmitted(true);
          localStorage.setItem("hasSubmitted", "true");
        }
      })
      .catch((error) => console.error("Error fetching data:", error)); // let saveData = new FormData();
    localStorage.setItem("hasSubmitted", "true");

    setHasSubmitted(true);
  };
  const handleSend = () => {
    const isAllSelected = topics.every((topic) =>
      level.some((sel) => sel.topicId === topic.id)
    );

    if (!isAllSelected) {
      // 如果有未選擇的 Radio
      message.error("尚未填寫完畢");
      return; // 中斷執行
    }
    const data = level.map((item) => ({
      ...item,
      projectid,
      stackholderid,
    }));

    console.log(data);

    api
      .post("/addquestionnaire", data)
      .then((result) => {
        console.log(result.status);
        if (result.status === 201) {
          console.log("成功");
          // 跳轉畫面
          setHasSubmitted(true);
          localStorage.setItem("hasSubmitted", "true");
        }
      })
      .catch((error) => console.error("Error fetching data:", error)); // let saveData = new FormData();
    console.log(level);
  };
  if (isLoading) return <Spin size="large" tip="Loading..." fullscreen />;

  return (
    <>
      {hasSubmitted ? (
        <div className="thank-you-container">
          <div className="icon-pulse-container">
            <SendOutlined className="pulsing-icon" />
          </div>
          <h1>Thank You!</h1>
          <p>
            您的問卷已成功提交
            <br />
            非常感謝您花費時間和精力幫助我們完成調查永續報告書重大議題。
          </p>
        </div>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              background: "#fffdfb",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              padding: "3% 5%",
            }}
          >
            <h2 className="h2">
              {qinfo.corporateName} 重大議題{showImpact ? "衝擊" : "關注"}
              程度問卷
            </h2>
            <div
              style={{
                width: "90%",
              }}
            >
              <div className="questionnaire-intro">
                <div className="questionnaire-section">
                  各位利害關係人您好！
                  <br />
                  我們是 {qinfo.corporateName}
                  ，致力於在我們的業務中貫徹可持續發展和社會責任。為了更好地理解和響應利害關係人的期望與需求，我們正在進行一項關於重大議題關注程度的調查。您的見解對我們至關重要，將直接影響我們未來的經營規劃和行動方向。
                </div>
                <div className="questionnaire-section">
                  本問卷旨在收集您對於我們識別出的重大議題的{showImpact ? "衝擊" : "關注"}程度 。我們將這些議題分為多個領域，如環境保護、社會責任和公司治理等。針對每個議題，我們希望了解您認為其重要性的程度。{" "}
                </div>
                <div className="questionnaire-contact">
                  問卷大約需要您 3-5
                  分鐘的時間來完成。請您根據實際情況選擇對應的選項。如果您在填寫問卷過程中遇到任何問題，或希望與我們進一步溝通，歡迎通過以下方式聯繫我們：{" "}
                  <br />
                  <br />
                  聯絡人：{qinfo.questionnaireInfo.contacts}
                  <br />
                  電子信箱：{qinfo.questionnaireInfo.Email}
                </div>
              </div>
            </div>
            <h2 className="h3">
              針對下列議題，請依據您的{showImpact ? "衝擊程度" : "關注程度"}
              進行勾選：
            </h2>

            {showImpact ? (
              impactDescriptions.map((description, index) => (
                <>
                  <div className="form-block">
                    {description}
                  </div>
                  <div className="form-block-radio">
                    <Row className="topic-header">
                      <Col className="topic-column" span={3}>
                        面相
                      </Col>
                      <Col className="topic-column" span={3}>
                        重大議題
                      </Col>
                      <Col className="topic-column" span={12}>
                        定義與說明
                      </Col>
                      <Col className="topic-column" span={6}>
                        {showImpact ? "衝擊程度" : "關注程度"}
                      </Col>{" "}
                    </Row>
                    {topics.map((topic) => (
                      <Row key={topic.id} className="topic-row">
                        {/* Column 內容，根據需求調整 */}
                        <Col span={3} className="topic-column">
                          {topic.physiognomy}
                        </Col>
                        <Col span={3} className="topic-column">
                          {topic.materialTopics}
                        </Col>
                        {/* 這裡插入一個額外的 Column 來顯示當前的衝擊程度描述 */}
                        <Col span={12} className="topic-column">
                          {topic.description}
                        </Col>
                        <Col span={6} className="topic-column">
                          <Radio.Group
                            onChange={(e) =>
                              onChangeRadio2(topic.id, e, description)
                            }
                            value={
                              level.find(
                                (radio) =>
                                  radio.topicId === topic.id &&
                                  radio.impactType === description
                              )?.level
                            }
                          >
                            <Radio value={1}>1</Radio>
                            <Radio value={2}>2</Radio>
                            <Radio value={3}>3</Radio>
                            <Radio value={4}>4</Radio>
                            <Radio value={5}>5</Radio>
                          </Radio.Group>
                        </Col>
                      </Row>
                    ))}
                  </div>
                </>
              ))
            ) : (
              <div className="form-block-radio">
                <Row className="topic-header">
                  <Col className="topic-column" span={3}>
                    面相
                  </Col>
                  <Col className="topic-column" span={3}>
                    重大議題
                  </Col>
                  <Col className="topic-column" span={12}>
                    定義與說明
                  </Col>
                  <Col className="topic-column" span={6}>
                    {showImpact ? "衝擊程度" : "關注程度"}
                  </Col>
                </Row>
                {topics.map((topic) => (
                  <Row key={topic.id} className="topic-row">
                    <Col span={3} className="topic-column">
                      {topic.physiognomy}
                    </Col>
                    <Col span={3} className="topic-column">
                      {topic.materialTopics}
                    </Col>
                    <Col span={12} className="topic-column">
                      {topic.description}
                    </Col>
                    <Col span={6} className="topic-column">
                      <Radio.Group
                        onChange={(e) => onChangeRadio(topic.id, e)}
                        value={
                          level.find((radio) => radio.topicId === topic.id)
                            ?.level
                        }
                      >
                        <Radio value={1}>1</Radio>
                        <Radio value={2}>2</Radio>
                        <Radio value={3}>3</Radio>
                        <Radio value={4}>4</Radio>
                        <Radio value={5}>5</Radio>
                      </Radio.Group>
                    </Col>
                  </Row>
                ))}
              </div>
            )}
            <div style={{ margin: "3%" }}>
              <Button
                type="default"
                onClick={showImpact ? handleSend2 : handleSend}
              >
                送出
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OutQuestionnaire;
