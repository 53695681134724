import React, { useRef, useEffect,useState } from "react";
import {
  FundProjectionScreenOutlined,
  TeamOutlined,
  BankOutlined,
  ReadOutlined,
} from "@ant-design/icons";
import { Button, Progress } from "antd";
import "../style/home.scss";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import api from "../components/AxiosDefault";
import MatrixChart from "../components/MatrixChart";

const Home = (props) => {
  const navigate = useNavigate();
  // const chartRef = useRef(null);
  const decoded = jwtDecode(localStorage.getItem("access_token"));
  const [progressData, setProgressData] = useState(0);
  const [data, setData] = useState([]); // 儲存關注程度排行榜的資料

  useEffect(() => {
    getProgress();
    api
    .post("/TopicImpactView/", { corporateId: decoded["corporateId"] })
    .then((response) => {
      console.log(response.data);
      setData(response.data);
    })
    .catch((error) => console.error("Error fetching data:", error));
}, []);

  // 取得目前進度
  const getProgress = () => {
    api
      .get("/progressData/" + decoded["corporateId"])
      .then((response) => {
        setProgressData(response.data["progress"]);
      })
      .catch((error) => console.error("Error fetching data:", error));
  };
  return (
    <div className="home">
      <Progress
        percent={progressData}
        status="active"
        strokeColor={{ from: "#018DA5", to: "#89C6D9" }}
        className="progress"
      />
      <div className="hmoe_list">
        <div
          className="hmoe_list_item"
          style={{ background: "#507AB8" }}
          onClick={() => {
            navigate("/Information");
          }}
        >
          <BankOutlined className="icon" />
          <div className="text">公司基本資料</div>
        </div>
        <div
          className="hmoe_list_item"
          style={{ background: "#2C89BE" }}
          onClick={() => {
            navigate("/StakeholderProject");
          }}
        >
          <TeamOutlined className="icon" />
          <div className="text">利害關係人議合</div>
        </div>
        <div
          className="hmoe_list_item"
          style={{ background: "#40AAC1" }}
          onClick={() => {
            navigate("/ReportList");
          }}
        >
          <ReadOutlined className="icon" />
          <div className="text">報告書製作</div>
        </div>
        <div
          className="hmoe_list_item"
          style={{ background: "#4DC6BE" }}
          onClick={() => {
            navigate("/Records");
          }}
        >
          <FundProjectionScreenOutlined className="icon" />
          <div className="text"> 紀錄/歷史資料 </div>
        </div>
      </div>
      <div className="chart">
        <MatrixChart data={data} />
      </div>
    </div>
  );
};
export default Home;
