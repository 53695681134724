import React, { useState, useEffect, useRef } from "react";
import "../../style/MajorList.scss";
import { Table, Input, Space, Button, Modal, notification } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import api from "../../components/AxiosDefault";
import { SaveOutlined, SendOutlined } from "@ant-design/icons";
const MajorList = (props) => {
  const isEditable = props.isEditable;
  const [isSendModalOpen, setIsSendModalOpen] = useState(false); // 使否顯示新增Modal
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [apinot, contextHolder] = notification.useNotification();
  const [checkMajorIssues, setCheckMajorIssues] = useState();
  const [majorIssues, setMajorIssues] = useState([
    {
      id: 0,
      physiognomy: "0",
      materialTopics: "0",
      description: "",
      GRI: "",
      SASB: "",
      SDGs: "",
    },
  ]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      console.log("selectedRowKeys changed: ", newSelectedRowKeys);
      setSelectedRowKeys(newSelectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: !isEditable,
    }),
  };
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  useEffect(() => {
    api
      .get("/materialTopicsList")
      .then((response) => {
        console.log(response.status);
        setMajorIssues(response.data);
      })

      .catch((error) => console.error("Error fetching data:", error));
    api
      .get("/projectmaterialtopics/" + props["project"]["id"])
      .then((response) => {
        // console.log(response.data);
        setCheckMajorIssues(response.data);
      })
      .catch((error) => console.error("Error fetching topicIds:", error));
  }, []);
  // 調整勾選的狀態
  useEffect(() => {
    if (majorIssues && checkMajorIssues) {
      const preSelectedKeys = majorIssues
        .filter((issue) => checkMajorIssues.includes(issue.id))
        .map((issue) => issue.id);
      setSelectedRowKeys(preSelectedKeys);
      console.log(preSelectedKeys);
    }
  }, [majorIssues, checkMajorIssues]); // 当 majorIssues 或 checkMajorIssues 改变时执行
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          disabled={!isEditable} //////////////////////////////////////////////////////////////
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) => text,
  });
  const columns = [
    {
      title: "議題",
      dataIndex: "materialTopics",
      ...getColumnSearchProps("materialTopics"), // 应用搜索功能
    },
    {
      title: "面相",
      dataIndex: "physiognomy",
    },
    {
      title: "說明",
      dataIndex: "description",
    },
    {
      title: "GRI",
      dataIndex: "GRI",
      render: (text) =>
        text === null ? <div style={{ textAlign: "center" }}>-</div> : text,
      sorter: (a, b) => {
        // 如果a.title或b.title为null或undefined，则使用空字符串代替
        const aValue = a.GRI || "";
        const bValue = b.GRI || "";
        return aValue.localeCompare(bValue);
      },
    },
    {
      title: "SASB",
      dataIndex: "SASB",
      render: (text) =>
        text === null ? <div style={{ textAlign: "center" }}>-</div> : text,
      sorter: (a, b) => {
        // 如果a.title或b.title为null或undefined，则使用空字符串代替
        const aValue = a.SASB || "";
        const bValue = b.SASB || "";
        return aValue.localeCompare(bValue);
      },
    },
    {
      title: "SDGs",
      dataIndex: "SDGs",
      render: (text) =>
        text === null ? <div style={{ textAlign: "center" }}>-</div> : text,
      sorter: (a, b) => {
        // 如果a.title或b.title为null或undefined，则使用空字符串代替
        const aValue = a.SDGs || "";
        const bValue = b.SDGs || "";
        return aValue.localeCompare(bValue);
      },
    },
  ];
  const handleCancel = () => {
    setIsSendModalOpen(false);
  };

  const handleSave = () => {
    api
      .post("/projectmaterialtopics/" + props["project"]["id"], selectedRowKeys)
      .then((result) => {
        console.log(result.status);
        if (result.status === 201) {
          apinot["success"]({
            message: "通知",
            description: "儲存成功",
          });
        }
      })
      .catch((error) => console.error("Error fetching data:", error)); // let saveData = new FormData();
  };

  const handleSendOk = () => {
    let saveData = new FormData();
    console.log(props["project"]["id"]);
    saveData.append("projectid", props["project"]["id"]);
    saveData.append("state", "完成蒐集重大議題");
    handleSave();
    api.patch("/projects", saveData).then((result) => {
      if (result.status === 200) {
        // setIsEditable(false);
        props.setIsEditable("完成蒐集重大議題");
        console.log(result);
        apinot["success"]({
          message: "通知",
          description: "成功送出",
        });
      }
    });
    setIsSendModalOpen(false);
  };
  const handleSend = () => {
    setIsSendModalOpen(true);
  };
  return (
    <>
      {contextHolder}
      <div className="majorList">
        <h2 className="h2title">重大議題清單</h2>
        <Table
          rowKey={(record) => {
            return record.id;
          }}
          rowSelection={rowSelection}
          className="table"
          dataSource={majorIssues}
          columns={columns}
          pagination={{ pageSize: 50 }}
        />
        {isEditable && (
          <div style={{ display: "flex" }}>
            <Button
              style={{ margin: "1%" }}
              type="primary"
              onClick={handleSave}
              icon={<SaveOutlined />}
            >
              儲存
            </Button>
            <Button
              type="primary"
              style={{ margin: "1%" }}
              onClick={handleSend}
              icon={<SendOutlined />}
            >
              送出 (送出後不得修改)
            </Button>
          </div>
        )}
      </div>
      <Modal
        title="是否送出"
        open={isSendModalOpen}
        onOk={handleSendOk}
        onCancel={handleCancel}
      >
        <p>送出後不得修改</p>
      </Modal>
    </>
  );
};

export default MajorList;
